<template>
  <div class="modrEditMain" v-loading="loadFlag">
    <el-row v-sticky class="vd_edhea vg_button_group modr_header" style="padding-top: 16px; background: white">
      <el-col>
        <editHeader :isShow="isShow" :btn="btn" :strForm="modrForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('modrForm')" />
        <div v-if="isShow" class="vd_export" style="padding-top: 16px">
          <el-button type="primary" size="small" @click="buttonRefresh()">刷新</el-button>
          <el-button
            v-if="($cookies.get('userInfo').stff_id === 1 || $cookies.get('userInfo').dept_id === modrForm.modr_dept_id) && modrForm.status === 2"
            class="vg_ml_8"
            size="small"
            type="danger"
            @click="sendEmailDialogVisible = true"
            >发送邮件
          </el-button>
          <el-popover placement="right" trigger="hover" width="800">
            <el-button class="vg_mb_8" icon="el-icon-printer" size="small" type="info" @click="clickPrint">打印</el-button>
            <el-button v-if="modrForm.status === 2" icon="el-icon-bottom" size="small" type="primary" @click="clickExport">下载PDF</el-button>
            <el-button v-if="modrForm.status === 2" size="small" type="warning" @click="generateProd">生成材料采购变更单</el-button>
            <el-button
              v-if="modrForm.status === 2 && isBussinessDept"
              :disabled="modrForm.modr_stat === 1"
              :type="modrForm.modr_stat === 1 ? 'info' : 'warning'"
              size="small"
              @click="changeStatus"
              >{{ modrForm.modr_stat === 1 ? '已下单' : '下单' }}
            </el-button>
            <el-button v-if="modrForm.status === 2" plain size="small" type="warning" @click="syncModrToMes">同步mes</el-button>
            <br />
            <el-button v-if="modrForm.status !== 0" size="small" type="primary" @click="viewProductionControlTable()"> 查看生产控制表</el-button>
            <el-date-picker
              v-if="modrForm.status !== 0"
              v-model="dateRange2"
              class="topDateRangeSearch vg_mr_8"
              end-placeholder="合同结束日期"
              range-separator="至"
              size="small"
              start-placeholder="合同开始日期"
              style="margin-left: 10px"
              type="daterange"
              value-format="timestamp"
            />
            <el-button slot="reference" class="vg_ml_8" size="small" type="primary">其他操作</el-button>
          </el-popover>
        </div>
        <div class="vd_export2 vg_mr_8" style="padding-top: 16px">
          <span style="margin-right: 2px">邮件发送状态:</span>
          <el-tag v-if="modrForm.email_status === 1" class="vg_mr_8" size="mini" type="success"> 已发送</el-tag>
          <el-tag v-else class="vg_mr_8" size="mini" type="info"> 未发送</el-tag>
          <span style="margin-right: 2px">采购合同编号:</span>
          <el-input size="mini" style="width: 150px" disabled v-model="modrForm.modr_no" show-word-limit></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form :model="modrForm" ref="modrForm" label-width="120px" size="mini" :rules="modrFormRules" :disabled="isShow">
          <el-row>
            <el-col :md="8">
              <el-form-item label="销售合同号" prop="scon_no">
                <el-input disabled v-model="modrForm.scon_no" placeholder="暂无销售合同号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="下单状态" prop="modr_stat">
                <el-select v-model="modrForm.modr_stat" :disabled="modrForm.status === 2" placeholder="请选择下单状态" disabled>
                  <el-option :value="0" label="未下单" v-if="modrForm.status !== 2"></el-option>
                  <el-option :value="1" label="已下单"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="合同号备注" prop="modr_note">
                <el-input v-model.trim="modrForm.modr_note" maxlength="10" show-word-limit placeholder="请填写单据提示"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="供应商简称" prop="supp_id">
                <el-select disabled v-model="modrForm.supp_id" placeholder="请选择供应商简称" filterable>
                  <el-option v-for="item in suppOptionList" :key="item.supp_id" :value="item.supp_id" :label="item.supp_abbr"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="公司抬头" prop="cptt_id">
                <el-select v-model="modrForm.cptt_id" placeholder="请选择公司抬头">
                  <el-option v-for="item in cpttOptionList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="合同日期" prop="modr_cndate">
                <el-date-picker v-model="modrForm.modr_cndate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="客户简称" prop="cust_abbr">
                <el-input v-model="modrForm.cust_abbr" disabled placeholder="暂无客户简称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="最终客户" prop="cust_babbr">
                <el-input v-model="modrForm.cust_babbr" disabled placeholder="暂无"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="要求交货日期" prop="modr_pedate">
                <el-date-picker v-model="modrForm.modr_pedate" @change="peDateChange" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="销售经办人" prop="cust_stff_id">
                <el-select @change="custStffIdChange" v-model="modrForm.cust_stff_id" placeholder="请选择外销经办人" filterable>
                  <el-option v-for="item in stffOptionList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="销售所属部门" prop="cust_dept_id">
                <el-select v-model="modrForm.cust_dept_id" placeholder="请选择外销所属部门" filterable>
                  <el-option v-for="item in deptOptionList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="工厂账期" prop="supp_creday">
                <el-input
                  v-model="modrForm.supp_creday"
                  placeholder="请填写工厂账期"
                  @change="credayChange"
                  maxlength="3"
                  @input="modrForm.supp_creday = helper.pureNumber(modrForm.supp_creday)"
                  show-word-limit
                >
                  <template slot="append">天</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="采购经办人" prop="modr_stff_id">
                <el-select @change="podrStffIdChange" v-model="modrForm.modr_stff_id" placeholder="请选择采购经办人" filterable>
                  <el-option v-for="item in stffOptionList" :key="item.stff_id" :value="item.stff_id" :label="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="采购所属部门" prop="modr_dept_id">
                <el-select v-model="modrForm.modr_dept_id" placeholder="请选择采购所属部门" filterable>
                  <el-option v-for="item in deptOptionList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="预计付款日期" prop="modr_ppdate">
                <el-date-picker v-model="modrForm.modr_ppdate" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="货款金额" prop="modr_prtotal">
                <el-input disabled v-model="modrForm.modr_prtotal" placeholder="暂无货款金额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="费用金额" prop="modr_fetotal">
                <el-input disabled v-model="modrForm.modr_fetotal" placeholder="暂无费用金额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="结算方式" prop="modr_payway">
                <el-select v-model="modrForm.modr_payway" placeholder="请选择结算方式">
                  <el-option :value="1" label="我司结算"></el-option>
                  <el-option :value="2" label="工厂结算"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="合同总额" prop="modr_total">
                <el-input disabled v-model="modrForm.modr_total" placeholder="暂无合同总额">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="是否已托收" prop="modr_wiped">
                <el-select v-model="modrForm.modr_wiped" placeholder="请选择是否已托收">
                  <el-option :value="0" label="未托收"></el-option>
                  <el-option :value="1" label="已托收"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="草拟导入验货" prop="modr_inspection">
                <el-select v-model="modrForm.modr_inspection" placeholder="请选择">
                  <el-option v-for="item in [{ value: 1, label: '是' }]" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8">
            <el-col :md="8">
              <el-form-item label="确认发货" prop="confirm_shipment">
                <el-select v-model="modrForm.confirm_shipment" placeholder="请选择" disabled>
                  <el-option
                    v-for="item in [
                      { value: 0, label: '未发货' },
                      { value: 1, label: '部分发货' },
                      { value: 2, label: '已发货' }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="AQL标准" prop="aql_standard">
                <el-select clearable v-model="modrForm.aql_standard" placeholder="请选择">
                  <el-option
                    v-for="item in [
                      { value: '0.65', label: '0.65' },
                      { value: '1.0', label: '1.0' },
                      { value: '1.5', label: '1.5' },
                      { value: '2.5', label: '2.5' },
                      { value: '4.0', label: '4.0' },
                      { value: '6.0', label: '6.0' }
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户订单号" prop="scon_cust_no">
                <el-input v-model="modrForm.scon_cust_no" disabled placeholder="暂无客户订单号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8 vg_button_group">
            <el-col :md="8">
              <el-form-item label="托收单号" prop="inform_no">
                <el-input v-model="modrForm.inform_no" disabled placeholder="暂无托收单号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mt_8 vg_button_group">
            <el-col>
              <ModrMtrbList
                :isShow="isShow"
                :modrForm="modrForm"
                :scon_id="modrForm.scon_id"
                :suppOptionList="suppOptionList"
                :podrOptionList="podrOptionList"
                ref="modrMtrbListRef"
                @mtrbPriceChange="mtrbPriceChange"
                :loadFlag="loadFlag"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-tabs v-model="activeName">
                <el-tab-pane :key="'second'" label="条款明细" name="second">
                  <ModrClauInfo :isShow="isShow" :modrForm="modrForm" @sconClauChange="sconClauChange" />
                </el-tab-pane>
                <el-tab-pane label="费用明细" name="first" :key="'first'">
                  <ModrFeesList :modrForm="modrForm" @feetPriceChange="feetPriceChange" ref="modrFeeListRef" />
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog title="生成材料采购变更单" :visible.sync="dialogVisible1" width="70%">
      <ImportModr :ModrList="modrForm.modr_mtrb_list" :modrId="modrForm.modr_id" @generateCancel="generateCancel"></ImportModr>
    </el-dialog>
    <el-dialog :visible.sync="sendEmailDialogVisible" title="发送邮件" width="70%">
      <el-input
        v-model="emailDialogFrom.emailContent"
        :rows="6"
        class="vg_mb_8"
        maxlength="200"
        placeholder="请填写邮件文本"
        show-word-limit
        type="textarea"
      ></el-input>
      <docu-about ref="docuAbout" :showBtn="false" :showSelectedFileSize="true"></docu-about>
      <span slot="footer">
        <el-button type="success" @click="sendEmail">发送</el-button>
      </span>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import ModrMtrbList from './Component/ModrMtrbList.vue';
import ModrFeesList from './Component/ModrFeesList.vue';
import ModrClauInfo from './Component/ModrClauInfo.vue';
import { get, post } from '@/api/request';
import { modrAPI } from '@/api/modules/modr';
import { useCpttMixin, useDeptMixin, useStffMixin, useSuppMixin } from '@/assets/js/mixin/initMixin';
import ImportModr from './Component/ImportModr.vue';
import { podrAPI } from '@api/modules/podr';
import { changeStaffForm, getDept } from '@api/public';
import { getArrayIds } from '@assets/js/arrayUtils';
import { BigNumber } from 'bignumber.js';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import UrlEncode from '@assets/js/UrlEncode';
import { emailAPI } from '@api/modules/email';
import DocuAbout from '@/views/component/docuCommon/docuAbout.vue';

export default {
  name: 'modrEditMain',
  mixins: [useSuppMixin, useCpttMixin, useStffMixin, useDeptMixin],
  components: {
    DocuAbout,
    editHeader,
    inputUser,
    ModrMtrbList,
    ModrFeesList,
    ModrClauInfo,
    ImportModr
  },
  provide() {
    return {
      initData: this.initData
    };
  },
  data() {
    return {
      isShow: true,
      loadFlag: true,
      activeName: 'second',
      btn: { edit: true },
      stffForm: {},
      modrForm: {
        modr_id: null,
        scon_no: null, //销售合同号
        modr_stat: 0, //下单状态
        modr_note: null, //单据提示
        supp_id: null, //供应商
        cptt_id: null, //公司信息
        modr_cndate: null, //合同日期
        cust_abbr: null, //客户简称
        scon_cust_no: null, //客户订单号
        cust_stff_id: null, //外销经办人
        cust_dept_id: null, //外销所属部门
        supp_creday: null, //工厂账期
        modr_stff_id: null, //采购经办人
        modr_dept_id: null, //采购所属部门
        modr_ppdate: null, //预计付款日期
        modr_prtotal: '0.0000', //货款金额
        modr_fetotal: '0.00', //费用金额
        modr_total: '0.00', //合同总额
        modr_payway: 1, //结算方式
        modr_wiped: 0, //是否依托收
        modr_fees_list: [],
        modr_mtrb_list: [],
        scon_id: null
      },
      modrFormRules: {
        supp_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cptt_id: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_cndate: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_pedate: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_ppdate: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_dept_id: [{ required: true, message: ' ', trigger: 'blur' }],
        modr_dept_id: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      podrOptionList: [],
      modrMtrbListCopy: [],
      dialogVisible1: false,
      isBussinessDept: false,
      dateRange2: [],
      sendEmailDialogVisible: false,
      emailDialogFrom: {
        emailContent: '',
        documentPointerList: []
      }
    };
  },
  watch: {
    //费用金额 改变计算总金额
    'modrForm.modr_fetotal': {
      handler(newVal, oldVal) {
        this.modrForm.modr_total = new BigNumber(Number(newVal) + Number(this.modrForm.modr_prtotal)).toFixed(2);
      }
    },
    //货款金额 改变计算总金额
    'modrForm.modr_prtotal': {
      handler(newVal, oldVal) {
        this.modrForm.modr_total = new BigNumber(Number(newVal) + Number(this.modrForm.modr_fetotal)).toFixed(2);
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.getModrInfo();
      // setTimeout(() => this.getPodrByScon());
      let fuliao = await getDept('辅料部');
      this.isBussinessDept = getArrayIds([...fuliao], 'value').includes(Number(this.$cookies.get('userInfo').dept_id));
    },
    getPodrByScon() {
      get(podrAPI.getPodrBySconId, { scon_id: this.modrForm.scon_id })
        .then(res => {
          if (res.data.code === 0) {
            this.podrOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.show = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已取消!'
          });
        })
        .catch(() => {});
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let partDynamicUTable = (await this.$refs.modrMtrbListRef?.$refs.multiTable.fullValidateMsg()) || null;
          this.$refs[formName].validate((valid, object) => {
            if (valid && !partDynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (partDynamicUTable) tempStr += `,材料子表-${partDynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(() => {});
    },
    saveInfo() {
      try {
        let modrForm = JSON.parse(JSON.stringify(this.modrForm));
        modrForm.modr_cndate = this.helper.getNewDate(modrForm.modr_cndate); //合同日期
        modrForm.modr_pedate = this.helper.getNewDate(modrForm.modr_pedate); //要求交货日期
        modrForm.modr_ppdate = modrForm.modr_ppdate ? this.helper.getNewDate(modrForm.modr_ppdate) : 0; //预计付款日期
        modrForm.modr_fees_list.push(...this.$refs.modrFeeListRef.delList); //合并 数据库中被删除的费用明细
        this.modrMtrbListCopy = this.modrMtrbListCopy.filter(item => modrForm.modr_mtrb_list.every(item1 => item.modr_mtrb_id !== item1.modr_mtrb_id));
        for (let i in this.modrMtrbListCopy) {
          this.modrMtrbListCopy[i].destroy_flag = 1;
        }
        modrForm.modr_mtrb_list = modrForm.modr_mtrb_list.concat(this.modrMtrbListCopy);
        modrForm.modr_mtrb_list.forEach(item => {
          item.modr_mtrb_pedate = this.helper.getNewDate(item.modr_mtrb_pedate);
          item.modr_mtrb_redate = this.helper.getNewDate(item.modr_mtrb_redate);
        });
        post(modrAPI.editModr, modrForm)
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              this.isShow = true;
              this.$emit('isShow', this.isShow);
              this.initData();
            } else if (res.data.code === 999) {
              this.$message.error(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      } catch (e) {
        console.info('🚀 ~ file:ModrEditMain.vue time:下午4:16 line:499 -----', e);
      }
    },
    // 生成材料采购变更单弹框
    generateProd() {
      this.dialogVisible1 = true;
    },
    changeStatus() {
      modrAPI.editModrStat({ modr_id: this.modrForm.modr_id }).then(() => {
        this.initData();
        this.$message.success('下单成功!');
      });
    },
    // 同步mes
    syncModrToMes() {
      get(modrAPI.syncModrToMes, { modr_id: this.modrForm.modr_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$message({ message: '同步成功', type: 'success' });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 生成材料采购变更单弹框关闭
    generateCancel() {
      this.dialogVisible1 = false;
    },
    clickPrint() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.modrForm.modr_id;
          get(modrAPI.exportModrPdfById, { modr_id: str })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    //导出 下载·
    clickExport() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.modrForm.modr_id;
          get(modrAPI.exportModrPdfById, { modr_id: str })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, '材料采购合同明细.pdf');
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {});
    },
    // 外销经办人
    custStffIdChange(val) {
      for (let i = 0; i < this.stffOptionList.length; i++) {
        if (this.stffOptionList[i].stff_id === val) {
          this.modrForm.cust_dept_id = this.stffOptionList[i].dept_id;
        }
      }
    },
    // 采购经办人
    podrStffIdChange(val) {
      for (let i = 0; i < this.stffOptionList.length; i++) {
        if (this.stffOptionList[i].stff_id === val) {
          this.modrForm.modr_dept_id = this.stffOptionList[i].dept_id;
        }
      }
    },
    getModrInfo() {
      this.loadFlag = true;
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.modrForm.modr_id = props.form_id;
      get(modrAPI.getModrById, { modr_id: this.modrForm.modr_id })
        .then(res => {
          if (res.data.code === 0) {
            this.modrForm = res.data.data.form;
            this.emailDialogFrom.emailContent = res.data.data.form.emailContent;
            //获取成品采购合同号
            this.getPodrByScon();
            this.btn = res.data.data.btn;
            changeStaffForm(this.stffForm, this.modrForm);
            this.modrMtrbListCopy = JSON.parse(JSON.stringify(this.modrForm.modr_mtrb_list));
            this.modrForm.modr_cndate = this.modrForm.modr_cndate === 0 ? new Date().getTime() : Number(this.modrForm.modr_cndate) * 1000;
            // this.modrForm.modr_cndate = this.modrForm.modr_cndate === 0? null : this.modrForm.modr_cndate * 1000 //合同日期
            this.modrForm.modr_pedate = this.modrForm.modr_pedate === 0 ? null : this.modrForm.modr_pedate * 1000; //要求交货日期
            this.modrForm.modr_ppdate = this.modrForm.modr_ppdate ? this.modrForm.modr_ppdate * 1000 : null; //预计付款日期

            this.modrForm.modr_fees_list.forEach(item => {
              //费用材料数据处理
              item.modr_fees_usd = new BigNumber(item.modr_fees_usd).toFixed(4);
            });

            this.modrForm.modr_mtrb_list.forEach(item => {
              this.$set(item, 'mtrb_total_price', new BigNumber(item.mtrb_num * item.prod_mtrb_price).toFixed(4));
              item.prod_mtrb_price = new BigNumber(item.prod_mtrb_price).toFixed(4);
              item.modr_mtrb_pedate = item.modr_mtrb_pedate === 0 ? null : item.modr_mtrb_pedate * 1000;
              item.modr_mtrb_redate = item.modr_mtrb_redate === 0 ? null : item.modr_mtrb_redate * 1000;
              item.podr_id = item.podr_id ? item.podr_id : null;
            });
            this.modrForm.modr_prtotal = new BigNumber(this.modrForm.modr_prtotal).toFixed(4); //货款金额
            this.modrForm.modr_fetotal = new BigNumber(this.modrForm.modr_fetotal).toFixed(2); //费用金额
            this.modrForm.modr_total = new BigNumber(this.modrForm.modr_total).toFixed(2); //合同总额
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 要求交货日期 计算
    peDateChange(val) {
      if (val) {
        this.modrForm.modr_ppdate = this.helper.toTimeVal(val) * 1000 + this.modrForm.supp_creday * 86400000;
      }
      this.modrForm.modr_mtrb_list.forEach(item => {
        item.modr_mtrb_pedate = val ? this.helper.toTimeVal(val) * 1000 : null;
      });
    },
    // 工厂账期 计算
    credayChange() {
      if (this.modrForm.modr_pedate) {
        this.modrForm.modr_ppdate = this.helper.toTimeVal(this.modrForm.modr_pedate) * 1000 + this.modrForm.supp_creday * 86400000;
      }
    },
    //货款款金额 计算
    mtrbPriceChange(val) {
      this.modrForm.modr_prtotal = new BigNumber(val).toFixed(4);
    },
    //费用明细 改变
    feetPriceChange(value) {
      this.modrForm.modr_fetotal = new BigNumber(value).toFixed(2);
    },
    //导入验货标准货 条款内容
    sconClauChange({ type, value }) {
      if (type === '验货标准') {
        this.modrForm.modr_standard = value.clau_content;
      } else if (type === '条款内容') {
        this.modrForm.clau_content = value.clau_content;
      } else if (type === '送货地址') {
        this.modrForm.modr_address = value.clau_content;
      } else if (type === '合同注意事项') {
        this.modrForm.modr_remark = value.clau_content;
      }
    },
    //外销经办人 改变 外销经办部门
    custStffChange(row) {
      let current = this.stffOptionList.filter(item => item.stff_id === row);
      this.modrForm.cust_dept_id = current[0].dept_id;
    },
    viewProductionControlTable() {
      if (!this.dateRange2) {
        this.dateRange2 = [];
      }
      this.jump('/prodcontrol_list', {
        perm_id: 187,
        start_purchase_contract_time: this.dateRange2[0],
        end_purchase_contract_time: this.dateRange2[1],
        cust_catalog_number: getArrayIds(this.modrForm.modr_mtrb_list, 'cust_catalog_number').toString(),
        flag: '材料',
        t: Date.now()
      });
    },
    //更改发货装填
    changeShipment(val) {
      this.$confirm('确定更改发货状态？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          modrAPI.edit_confirm_shipment({ modr_id: this.modrForm.modr_id, confirm_shipment: val }).then(({ data }) => {
            this.$message.success('更改成功!');
            //刷新
            this.buttonRefresh();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    sendEmail() {
      this.emailDialogFrom.stff_id = this.modrForm.modr_stff_id;
      this.emailDialogFrom.supp_id = this.modrForm.supp_id;
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      this.emailDialogFrom.form_id = props.form_id;
      this.emailDialogFrom.perm_id = props.perm_id;
      this.emailDialogFrom.documentPointerList = this.$refs.docuAbout.multipleSelection;
      emailAPI.sendEmail(this.emailDialogFrom).then(({ data }) => {
        this.$message.success('发送成功!');
        this.sendEmailDialogVisible = false;
        this.buttonRefresh();
      });
    }
  }
};
</script>

<style scoped>
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}

.vd_export2 {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 150px;
}

.vd_edhea {
  position: relative;
}
</style>
